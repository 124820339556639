
import { Component, Vue } from 'vue-property-decorator';
import {Fancybox} from "@fancyapps/ui";

@Component
export default class GalleriaEstesa extends Vue {
  private backgroundCss = {"background-image": `url(${require('@/assets/marble3.jpg')})`}
  private albums = [
    {
      name: "Arte sacra",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Arte sacra\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Arte sacra/${x}`)
      })(),
      open: false
    },
    {
      name: "Camini",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Camini\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Camini/${x}`)
      })(),
      open: false
    },
    {
      name: "Lavabi",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Lavabi\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Lavabi/${x}`)
      })(),
      open: false
    },
    {
      name: "Numeri civici e targhe",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Numeri civici e targhe\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Numeri civici e targhe/${x}`)
      })(),
      open: false
    },
    {
      name: "Piatti doccia",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Piatti doccia\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Piatti doccia/${x}`)
      })(),
      open: false
    },
    {
      name: "Scale",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Scale\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Scale/${x}`)
      })(),
      open: false
    },
    {
      name: "Vari",
      images: (()=> {
        const getFile = require.context("@/assets/", false, /Vari\.json$/)
        return getFile(getFile.keys()[0]).map(x => `/images/Vari/${x}`)
      })(),
      open: false
    }
  ]

  scrollIntoGalleria(){
    setTimeout(() => {
        document.querySelector('#galleriaestesa').scrollIntoView({behavior: 'smooth'})
    }, 1)
  }

  mounted() {
    this.albums.forEach(async album => {
      setTimeout(() => {
        this.createFancybox(album.name)
      }, 2000)
    })
  }

  createFancybox(name: string): void {
    Fancybox.bind(`[data-fancybox="${name}"]`, {
      Carousel: {
        on: {
          "Carousel.*": e => console.log(e),
          change: (that) => {
            document.querySelector(`[data-fancybox="${name}"]:nth-of-type(${that.page+1})`)
                .scrollIntoView({behavior: "smooth"})
          },
        },
      },
    });
  }
}
